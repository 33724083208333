.category_card {
    padding: 15px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    cursor: pointer;
    transition: box-shadow 0.5s;
}
.category_card:hover {
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}
.category_card_overlay {
    border: 1px solid var(--clr-white);
    width: 100%;
    height: 100%;
    position: relative;
}
.category_card__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: fit-content;
    transform: translate(-50%, -50%);
}
.category_card__inner p {
    font-size: 12px;
    letter-spacing: 2px;
    color: var(--clr-black);
    background-color: var(--clr-white);
    padding: 10px;
    line-height: 1.5;
    font-weight: 500;
    text-transform: uppercase;
    width: max-content;
}
