.spinner {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border-top: 5px solid var(--clr-black);
    border-right: 5px solid var(--clr-black);
    border-bottom: 5px solid var(--clr-rose);
    border-left: 5px solid var(--clr-rose);
    animation: spinner 1s linear infinite;
}

@keyframes spinner {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
