.product_card {
    text-align: center;
    overflow: hidden;
}
.wrapper {
    border: 1px solid var(--clr-rose);
    border-top: none;
    border-radius: 10px;
    word-wrap: break-word;
    overflow: hidden;
}
.product_card__product_image {
    height: 320px;
    width: 100%;
}
.product_card__product_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.product_card__name {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1.5px;
    text-transform: capitalize;
    padding: 15px 5px;
    color: var(--clr-black);
}
.product_card__price {
    font-weight: 500;
    letter-spacing: 1.2px;
    font-size: 20px;
    padding-bottom: 20px;
}
.product_card__actions {
    display: flex;
    padding: 0 10px 20px 10px;
    justify-content: space-around;
}
.product_card__add_to_cart,
.product_card__view_more {
    flex: 0 0 45%;
    padding: 10px;
    border-radius: 20px;
    min-height: 40px;
    position: relative;
    border: none;
}
.product_card__add_to_cart {
    background-color: var(--clr-rose);
}
.product_card__add_to_cart::after,
.product_card__view_more::after {
    content: "";
    position: absolute;
    inset: 0;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
}
.product_card__view_more::after {
    background-image: url("../../assets/icons/eye.svg");
    background-size: 35px;
}
.product_card__add_to_cart::after {
    background-image: url("../../assets/icons/cart-white.svg");
    background-size: 25px;
}
