.swiper__product_images  {
    --swiper-navigation-size: 20px;
    --swiper-navigation-color: var(--clr-black) ;
}
.swiper__product_image_slide {
   padding-top: 100%;
   overflow: hidden;
   position: relative;
}
.swiper__product_image_slide  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: inherit;
}
.swiper__product_thumb_slide {
    cursor: pointer;
    border: 1px solid var(--clr-white);
    margin-top: 10px;
}
.swiper__product_thumb{
    width: 100%;
    padding-top: 100%;
    overflow: hidden;
    position: relative;
}
.swiper__product_thumb img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
.swiper__product_thumbs div[class*="swiper-slide-thumb-active"] {
    border:  1px solid var(--clr-black);
}