.category_wrapper {
    padding: 50px 0;
}
.wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 200px;
    gap: 50px 40px;
}   
.categories__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@media only screen and (max-width: 610px) {
    .wrapper {
        grid-template-columns: 1fr;
    }
}
@media only screen and (min-width: 610px) and (max-width: 1200px) {
    .wrapper { 
        grid-template-columns: 1fr 1fr;
    }
}