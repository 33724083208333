.summary__wrapper {
    padding: 10px;
}
.summary__title {
    margin-bottom: 10px;
    font-size: 15px;
}
.summary__form_title {
    font-size: 15px;
}
.summary__info_table {
    width: 100%;
    margin-bottom: 20px;
}
.summary__info_table td {
    font-size: 13px;
    font-weight: 600;
    padding: 3px 0;
}
.summary__form label {
    font-size: 13px;
    font-weight: 600;
    display: block;
    padding: 5px 0;
    margin-top: 10px;
    position: relative;
}
.summary__form label::after {
    content: "*";
    position: absolute;
    color: var(--clr-black);
}
.summary__form input[type=text] {
    padding: 8px;
    width: 100%;
    outline: none;
    font-size: 13px;
    font-weight: 500;
    border: 1px solid var(--clr-black);
}
.summary__form_phone_no{
    display: inline-flex;
    align-items: center;
    width: 100%;
    border: 1px solid var(--clr-black);
    text-align: center;
} 
.summary__form_phone_no p {
    font-size: 13px;
    padding: 0 5px;
    font-weight: 500;
}
.summary__form_phone_no input[type=text] {
    border: none;
    outline: none;
}
.summary__form button {
    padding: 10px 30px;
    display: block;
    width: 100%;
    margin: auto;
    margin-top: 1.5rem;
    background-color: var(--clr-black);
    border-radius: 10px;
    color: var(--clr-white);
    cursor: pointer;
}
.summary__form button:disabled {
    cursor: not-allowed;
    opacity: 0.8;
}