.cart__wrapper {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    padding: 15px 0;
    gap: 15px;
}
.cart__cart_wrapper {
    flex: 1;
}
.cart__summary_wrapper {
    flex: 1;
    height: fit-content;
    border: 1px solid var(--clr-rose);
}
.cart__cart_empty {
    height: 100%;
    width: 100px;
    margin: auto;
}
.cart__cart_empty img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    opacity: 0.7;
}
@media only screen and (max-width: 610px) {
    .cart__wrapper {
        flex-direction: column;
    }
}