.cart_item__wrapper {
    display: flex;
    border: 1px dashed var(--clr-rose);
    padding: 10px;
    flex-wrap: wrap;
    gap: 15px;
}

.cart_item__product_image {
    width: 150px;
    height: 150px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
}

.cart_item__product_image img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
}
.cart_item__product_name {
    font-size: 18px;
    color: var(--clr-rose);
    margin-bottom: 10px;
    max-width: 320px;
    overflow-wrap: anywhere;
}

.cart_item__product_price {
    font-size: 15px;
    font-weight: 600px;
}

.cart_item__qty_actions {
    display: inline-flex;
    height: 30px;
    padding: 5px;
    width: 100px;
    font-size: 18px;
    margin: 15px 0;
    justify-content: center;
    max-width: 120px;
    border: 1px solid var(--clr-black);
    border-radius: 40px;
}

.cart_item__qty_actions button {
    width: 30px;
    border: none;
    outline: none;
    font-weight: 600;
    cursor: pointer;
    background-color: transparent;
}

.cart_item__qty_actions input {
    width: 35px;
    border: none;
    outline: none;
    text-align: center;
    background-color: transparent;
}

.cart_item__qty_actions input[type=number]::-webkit-inner-spin-button,
.cart_item__qty_actions input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.cart_item__product_total {
    font-size: 15px;
    font-weight: 700;
}
.cart_item__product_total_lbl{
    color: var(--clr-green);
    padding-right: 10px;
} 
.cart_item__delete {
    position: absolute;
    height: 30px;
    width: 30px;
    top: 5px;
    left: 5px;
    padding: 5px;
    border-radius: 10px;
    background-color: var(--clr-white);
    z-index: 1;
}
.cart_item__delete img {
    height: 100%;
    width: 100%;
    cursor: pointer;
    object-fit: contain;
}